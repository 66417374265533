import { Injectable } from '@angular/core';
import{IUserProfile} from '../interfaces/helper/user-profile';
import { Observable, observable } from 'rxjs';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import 'rxjs/add/observable/of';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { NgSwitch } from '@angular/common';
import{PmsRoles} from '../enums/pmsEnums';

//enum PmsRoles {Admin_All, Admin_Department};

@Injectable({
  providedIn: 'root'
})

//enum PmsRoles {Admin_All, Admin_Department};

export class UserProfileService {

  private userProfile:IUserProfile;
  private getUserProfileUrl='api/pmsUser/getUserProfile';
  profileUpdated=new BehaviorSubject(false);
  logoFullPath:string='';
  public pmsLogo:string='pmsLogoSmall.jpg';
  public generalParamID: any; //to hold any global id
  //Roles Enum
  public currentRole:PmsRoles;

  constructor(private http:HttpClient) {

    this.resetProfile();
   }
currentUserProfile():Observable<IUserProfile>{
  //TODO: get from server
  return Observable.of(this.userProfile);
}
clearUserProfile():void{
 this.resetProfile();
}

private setUserProfile(userprofile:IUserProfile):void{


  //TODO: get it from db once and assign it to this.userProfile

  //test assign to arbitrary value


  this.userProfile={
    accessLevel:userprofile.accessLevel,
    isAssessor:userprofile.isAssessor,
    assessorId:userprofile.assessorId,
    assessorLevel:userprofile.assessorLevel,
    departmentId:userprofile.departmentId,
    loginId:userprofile.loginId,
    userName:userprofile.userName,
    userTitle: userprofile.userTitle,
    canViewAll: false,
    isPM: false
  };

  //console.log('user profile object set',this.userProfile);
  //set current role
  switch(this.userProfile.accessLevel){
    case 'Admin-All':this.currentRole=PmsRoles.Admin_All;
      break;
    case 'Admin-Department':this.currentRole=PmsRoles.Admin_Department;
      break;
    case 'Normal':this.currentRole=PmsRoles.Normal;
      break;
    case 'Self':this.currentRole=PmsRoles.Self;
      break;
    case 'ViewsReport-All':this.currentRole=PmsRoles.ViewsReport_All;
      break;
    case 'ViewsReport-Department':this.currentRole=PmsRoles.ViewsReport_Department;
    break;
      default:
      console.log('Invalid access level',this.userProfile.accessLevel);
  }

  //this.currentRole=PmsRoles.Admin_Department;

}
private resetProfile():void{

  this.userProfile = {
    accessLevel: '',
    isAssessor: false,
    assessorId: '',
    assessorLevel: -1,
    departmentId: -1,
    loginId: '',
    userName: '',
    userTitle: '',
    canViewAll: false,
    isPM: false
  };
}
private getUserProfile(userID:string): Observable<IUserProfile> {
  const headers = new HttpHeaders().set('content-type', 'application/json');
  const params=new HttpParams().set('userID',userID);
  return this.http.get<IUserProfile>(this.getUserProfileUrl,{headers,params});
}

//public...interface
public setCurrentUserProfile(userID:string):void
{
  //1.get user profile from db
  //2. assign to the singleton user profile object
  //3. return complete status

  this.getUserProfile(userID).subscribe(profile=>{
  this.setUserProfile(profile); //set the user profile

  //console.log('user profile',profile);
    this.profileUpdated.next(true);
   // return Observable.of(true);
  });
  //return Observable.of(false);
}
public getLogo():any{
  this.logoFullPath = "assets/imgs/"+ this.pmsLogo;
  return this.logoFullPath;
}
}
