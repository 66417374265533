
import { NgModule } from '@angular/core';
//import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { Routes, RouterModule } from '@angular/router';
import{AuthGuardService} from './services/auth-guard.service';


const routes: Routes = [
  
  {path:'login',
  loadChildren:'./public/public.module#PublicModule'
  },
  {
    path:'members',
    canActivate:[AuthGuardService],
    loadChildren:'./members/members.module#MembersModule'
  },
  {
    path:'',
    redirectTo:'login',
    pathMatch:'full'
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
  
})
export class AppRoutingModule { }
