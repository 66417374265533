import { Injectable, Injector } from "@angular/core";
import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest } from "@angular/common/http";
import{AuthenticationService} from './authentication.service';

import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {
   }
  intercept(
      request: HttpRequest<any>,
      next: HttpHandler): Observable<HttpEvent<any>> {



      var auth = this.injector.get(AuthenticationService);
      var token = (auth.isLoggedIn()) ? auth.getAuth()!.token : null;
      //var token = (auth.isAuthenticated()) ? auth.getAuth()!.token : null;

      if (token) {

        //console.log('token intercepted',token,'at request: ',request);

          request = request.clone({
              setHeaders: {
                  Authorization: `Bearer ${token}`
              }
          });
      }else{ //note:added to track

        //console.log(".....no token intercepted..auth-interceptor-servies");

      }
      return next.handle(request);
  }
}
