import { EventEmitter, Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders,HttpErrorResponse } from "@angular/common/http";
import { Observable,throwError, of } from "rxjs";
//import { Observable } from 'rxjs/Observable';//
import 'rxjs/Rx';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
//import { Observable } from 'rxjs/Observable';

import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import 'rxjs/add/operator/map'
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError,map,tap } from "rxjs/operators";
import{LoginUser} from '../interfaces/helper/login-user';

import{UserProfileService} from '../services/user-profile.service';
import{TokenResponse} from '../interfaces/helper/token-response';

const TOKEN_KEY='auth-token'; //test only

@Injectable({
    providedIn: 'root'
  })
export class AuthenticationService {

   loginStatus:string='';

  authKey: string = "auth";
    clientId: string = "gopngpms";
    private loggedIn = new BehaviorSubject<boolean>(false); // {1}


  authenticationState=new BehaviorSubject(false);
  unauthorisedUser=new BehaviorSubject(false);

  constructor(private userProfileService:UserProfileService,
   private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: any) { 


  }

  /*
  login(user:LoginUser):Observable<boolean>{
    //include login logic/detail
   
    //TODO : OnSuccess of login
    var url = "api/token/auth";
        var data = {
            username: user.loginID,
            password: user.password,
            client_id: this.clientId,
            // required when signing up with username/password
            grant_type: "password",
            // space-separated list of scopes for which the token is issued
            scope: "offline_access profile email"
        };
        return this.getAuthFromServer(url, data);


  }
  refreshToken(): Observable<boolean> {
    var url = "api/token/auth";
    var data = {
        client_id: this.clientId,
        // required when signing up with username/password
        grant_type: "refresh_token",
        refresh_token: this.getAuth()!.refresh_token,
        // space-separated list of scopes for which the token is issued
        scope: "offline_access profile email"
    };
    console.log('calling getauthFromServer for fresh token');

    return this.getAuthFromServer(url, data);
}

  logout(){
    //inlcude logout logic
    this.setAuth(null);
    this.authenticationState.next(false);
    this.userProfileService.clearUserProfile();
    //test
  }
  isAuthenticated(){
    return this.authenticationState.value;
  }
  get isLoggedInStatus() {
    return this.authenticationState.asObservable(); 
  }

  isLoggedIn(): boolean {
      console.log('....calling isLoggedIn:Authentication.services...');
      
    if (isPlatformBrowser(this.platformId)) {
       return localStorage.getItem(this.authKey) != null;

    }
    return false;
}

getAuth(): TokenResponse | null {
  if (isPlatformBrowser(this.platformId)) {
      var i = localStorage.getItem(this.authKey);
      if (i) {
          return JSON.parse(i);
      }
  }

  return null;
}




getAuthFromServer(url: string, data: any): Observable<boolean> {
    console.log('infor passed to the server',data);
    console.log('infor passed to the server --url',url);
    
    return this.http.post<TokenResponse>(url, data)
        .map((res) => {
            let token = res && res.token;
            // if the token is there, login has been successful
            if (token) {
                // store username and jwt token
                this.setAuth(res);
                // successful login
                console.log('data',data);
                this.authenticationState.next(true);
                return true;
            }
            console.log('error at getAuthFromServer!!!!!');
          // return Observable.throw('Unauthorized');
        })
           .catch(error => {
             console.log('get from server error@@@@@@@@@@@@@@',error);
             this.loggedIn.next(false);
             this.authenticationState.next(false);
             return Observable.of(false);
           //  return error as Observable<any>;
          //  return new Observable<any>(error);
         }
     ); 
 }


/*

getAuthFromServer(url: string, data: any): Observable<boolean> {
    console.log('infor passed to the server',data);
    console.log('infor passed to the server --url',url);
    
    return this.http.post<TokenResponse>(url, data)
    .pipe(
        
            map((res) => {
                let token = res && res.token;
                // if the token is there, login has been successful
                if (token) {
                    // store username and jwt token
                    this.setAuth(res);
                    // successful login
                    console.log('data..response',data);
                    this.authenticationState.next(true);
                    return true;
                }
               return false;  //Observable.throw('Unauthorized');
            }
            ) //,
       // catchError(this.handleError())
    );
 }

*/
/*
 setAuth(auth: TokenResponse | null): boolean {
    if (isPlatformBrowser(this.platformId)) {
        if (auth) {
            localStorage.setItem(
                this.authKey,
                JSON.stringify(auth));
            // Todo: write userProfile for global obj here.....
              console.log('localstorage value',localStorage.getItem(this.authKey));
        }
        else {
            localStorage.removeItem(this.authKey);
            //Todo: remove userProile global object
        }
    }
    return true;
}
*/





  
//login(username: string, password: string): Observable<boolean> {
    login(user:LoginUser): Observable<boolean> {
        var url = "api/token/auth";
        var data = {
            username: user.loginID,
            password: user.password,
            client_id: this.clientId,
            // required when signing up with username/password
            grant_type: "password",
            // space-separated list of scopes for which the token is issued
            scope: "offline_access profile email"
        };
        return this.getAuthFromServer(url, data);
    }
    // try to refresh token
    refreshToken(): Observable<boolean> {
        var url = "api/token/auth";
        var data = {
            client_id: this.clientId,
            // required when signing up with username/password
            grant_type: "refresh_token",
            refresh_token: this.getAuth()!.refresh_token,
            // space-separated list of scopes for which the token is issued
            scope: "offline_access profile email"
        };
        return this.getAuthFromServer(url, data);
    }
    // retrieve the access & refresh tokens from the server
    getAuthFromServer(url: string, data: any): Observable<boolean> {
        return this.http.post<TokenResponse>(url, data)
            .map((res) => {

               // console.log('...login server response...',res);

                let token = res && res.token;
                // if the token is there, login has been successful
                if (token) {
                    // store username and jwt token
                    this.setAuth(res);
                    // successful login
                    this.authenticationState.next(true);
                    this.loggedIn.next(true);
                    return true;
                }
               // console.log('---------XXXXXX----------');

              // return Observable.throw('Unauthorized');
            })
           
            
               .catch(error => {

                // console.log('get from server error',error);

                 this.loggedIn.next(false);
                this.authenticationState.next(false);

                
                // return error as Observable<any>;

                return Observable.of(false);
               // return new Observable<any>(error);
             }
         ); 
     }
     
     // performs the logout
     logout(): boolean {
         this.setAuth(null);
         this.authenticationState.next(false);
         this.loggedIn.next(false);
         return true;
     }
     isAuthenticated(){
        return this.authenticationState.value;
      }
     // Persist auth into localStorage or removes it if a NULL argument is given
     setAuth(auth: TokenResponse | null): boolean {
         if (isPlatformBrowser(this.platformId)) {
             if (auth) {
                 localStorage.setItem(
                     this.authKey,
                     JSON.stringify(auth));
                 // Todo: write userProfile for global obj here.....
                 //  console.log('localstorage value',localStorage.getItem(this.authKey));
             }
             else {
                 localStorage.removeItem(this.authKey);
                 //Todo: remove userProile global object
             }
         }
         return true;
     }
 
     // Retrieves the auth JSON object (or NULL if none)
     getAuth(): TokenResponse | null {
         if (isPlatformBrowser(this.platformId)) {
             var i = localStorage.getItem(this.authKey);
             if (i) {
                 return JSON.parse(i);
             }
         }
         return null;
     }
 
     // Returns TRUE if the user is logged in, FALSE otherwise.
     isLoggedIn(): boolean {
         if (isPlatformBrowser(this.platformId)) {
             return localStorage.getItem(this.authKey) != null;
         }
         return false;
     }
     get isLoggedInStatus() {
         return this.loggedIn.asObservable(); // {2}
       }

  

  //-----------------Error Handler------------------------------
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error("Error");
        //Backend returned code ${error.status};
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

}
