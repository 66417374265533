import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {  HTTP_INTERCEPTORS,HttpClientJsonpModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MenubarModule} from 'primeng/menubar';
import {MenuModule} from 'primeng/menu';


//import {ToastModule} from 'primeng/toast';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ButtonGroupModule } from '@progress/kendo-angular-buttons';

import { LabelModule } from '@progress/kendo-angular-label';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DialogsModule } from '@progress/kendo-angular-dialog';

import{AuthInterceptor} from './services/auth-interceptor.service';
import{AuthResponseInterceptor} from './services/auth-response-interceptor.service';
//import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
//import{DateFormatPipe,DateTimeFormatPipe} from './utilities/custom-pipes';




@NgModule({
  declarations: [
    AppComponent,
    AppFooterComponent,
  ],
  
  imports: [
    BrowserModule,
    AppRoutingModule,MenubarModule,MenuModule,HttpClientModule,
    AngularFontAwesomeModule,
    ButtonsModule,
    ButtonGroupModule, LabelModule, BrowserAnimationsModule,
    DateInputsModule, ChartsModule, LayoutModule, InputsModule, 
    TreeViewModule, DialogsModule,
    
  ],
  providers: [HttpClientJsonpModule,
   
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
  },
  {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthResponseInterceptor,
      multi: true
  },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
