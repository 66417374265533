import { Injectable } from '@angular/core';
import{CanActivate} from '@angular/router';
import{AuthenticationService} from './authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public auth:AuthenticationService) { }
  canActivate():boolean{
    //console.log('CanActivate',this.auth);
    return this.auth.isAuthenticated();
  }
}
